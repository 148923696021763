<template>
  <div class="videoEmbed" style="text-align: center">
    <video
      autoplay="autoplay"
      playsinline
      class="courseItemVid"
      height="349"
      width="560"
    >
      <source src="" type="video/mp4" />
      Your browser does not support the video tag.
    </video>
  </div>
</template>

<script>
export default {
  name: "EmbedContent",
};
</script>
