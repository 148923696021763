<template>
  <v-row>
    <v-col cols="12">
      <base-card>
        <v-card-title class="d-flex justify-space-between align-center mb-3">
          Videos
          <v-card-title class="d-flex justify-end align-center pa-0">
            <div class="select-filters">
              <v-select
                class="filter-input align-end"
                v-model="filters.language"
                v-on:change="paginate()"
                :items="languages"
                item-text="label"
                item-value="value"
                hide-details
                single-line
                outlined
                dense
              ></v-select>
            </div>
            <v-btn
              class="ml-2"
              color="primary"
              @click="$router.push(`/app/video/create`)"
            >
              <v-icon left>mdi-plus</v-icon>
              Create New
            </v-btn>
          </v-card-title>
        </v-card-title>
        <v-data-table
          :search="search"
          :headers="headers"
          :items="getVideos"
          :loading="getVideosLoading"
          item-key="id"
          class="elevation-1 data-table"
          multi-sort
        >
          <template v-slot:item.language="{ item }">
            <div>
              {{ item.language_name }}
            </div>
          </template>
          <template v-slot:item.video="{ item }">
            <div class="py-3 video-container">
              <video-player :input="item.video" :playerOptions="options" />
            </div>
          </template>
          <template v-slot:item.embed="{ item }">
            <div class="d-flex justify-center">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="success"
                    dark
                    v-bind="attrs"
                    v-on="on"
                    icon
                    @click="showEmbedDialog(item)"
                  >
                    <v-icon>mdi-code-not-equal-variant</v-icon>
                  </v-btn>
                </template>
                <span>Embed</span>
              </v-tooltip>
            </div>
          </template>
          <template v-slot:item.action="{ item }">
            <div class="d-flex justify-center">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="success"
                    dark
                    v-bind="attrs"
                    v-on="on"
                    icon
                    @click="$router.push(`/app/video/${item.id}/edit`)"
                  >
                    <v-icon>mdi-pencil-box-outline</v-icon>
                  </v-btn>
                </template>
                <span>Manage</span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="danger"
                    dark
                    v-bind="attrs"
                    v-on="on"
                    icon
                    @click="showRemoveDialog(item)"
                  >
                    <v-icon>mdi-trash-can-outline</v-icon>
                  </v-btn>
                </template>
                <span>Delete</span>
              </v-tooltip>
            </div>
          </template>
        </v-data-table>
      </base-card>
      <v-dialog v-model="dialog" max-width="290">
        <v-card v-if="selected">
          <v-card-title class="text-h6">
            Delete {{ selected.description }}
          </v-card-title>
          <v-card-text>
            The {{ selected.description }} video will be deleted permanently.
            Are you sure?
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="dialog = false"> Cancel </v-btn>
            <v-btn color="danger" text @click="removeVideo"> Delete </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <template>
        <v-row justify="center">
          <v-dialog v-model="embedDialog" width="500">
            <v-card v-if="selected">
              <v-card-title class="text-h6"> Embed Video Code </v-card-title>
              <v-card-text class="mt-2">
                <v-textarea
                  dense
                  readonly
                  solo
                  auto-grow
                  background-color="grey lighten-4"
                  v-model="content"
                  ref="content"
                  class="embed-text"
                ></v-textarea>
                <div class="d-flex justify-center">
                  <v-btn color="success" @click="copyText">
                    Copy To Clipboard
                  </v-btn>
                </div>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text @click="embedDialog = false"> Close </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>
      </template>
      <embed-content ref="embedContent" v-show="false"></embed-content>
    </v-col>
  </v-row>
</template>

<script>
import VideoPlayer from "@/components/VideoPlayer.vue";
import { get } from "lodash";
import { mapActions, mapGetters } from "vuex";
import EmbedContent from "./EmbedContent.vue";

export default {
  metaInfo: {
    title: "Videos",
  },
  components: {
    VideoPlayer,
    EmbedContent,
  },
  async mounted() {
    this.getLanguages.length ? this.getLanguages : await this.setLanguages();
  },
  data() {
    return {
      search: "",
      selected: null,
      content: null,
      embedDialog: false,
      dialog: false,
      headers: [
        { text: "Language", value: "language", sortable: false },
        { text: "Description", value: "description", sortable: false },
        {
          text: "Video",
          value: "video",
          width: "30%",
          sortable: false,
        },
        {
          text: "Embed Code",
          value: "embed",
          align: "center",
          sortable: false,
        },
        { text: "Action", value: "action", align: "center", sortable: false },
      ],
      options: {
        ratio: "4:2",
        height: "300px",
        width: "300px",
        controls: ["play", "progress", "download", "fullscreen"],
      },
      filters: {
        language: null,
      },
    };
  },

  methods: {
    ...mapActions(["setLanguages", "setVideos", "deleteVideo"]),

    paginate() {
      this.setVideos({
        ...this.filters,
      });
    },

    showRemoveDialog(item) {
      this.selected = item;
      this.dialog = true;
    },

    showEmbedDialog(item) {
      this.selected = item;

      let embedContent = this.$refs.embedContent.$el.outerHTML;
      this.content = embedContent.replace(
        'src=""',
        `src="${get(item, "video")}"`
      );

      this.embedDialog = true;
    },

    async removeVideo() {
      try {
        await this.deleteVideo(this.selected.id);

        this.selected = null;
        this.dialog = false;

        this.$store.dispatch("showSnackbar", "Video deleted.");
      } catch (error) {
        console.error("DEBUG: error", error);
        this.$store.dispatch("showSnackbar", "Oops, something went wrong!");
      }
    },

    async copyText() {
      if (window.isSecureContext && navigator.clipboard) {
        await navigator.clipboard.writeText(this.content);

        alert("Copied!");
      }
    },
  },

  computed: {
    ...mapGetters(["getVideos", "getVideosLoading", "getLanguages"]),

    languages() {
      const selections = [];
      selections.push({
        label: "All Languages",
        value: null,
      });

      this.getLanguages.forEach((item) => {
        selections.push({
          label: item.name,
          value: item.id,
        });
      });

      return selections;
    },
  },
};
</script>
<style lang="scss">
.select-filters {
  .filter-input {
    margin: 0 0.5rem;
    width: 12em;
  }
}
.video-container {
  max-width: 250px;
}
.embed-text {
  font-family: monospace;
}
</style>
